<template>
  <div id="app">
    <a-config-provider v-if="!loading" :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { getLogoConfigs } from "@/api/system";

export default {
  name: "App",
  data() {
    return {
      zh_CN,
      loading: true,
      logoItem: {},
    };
  },
  methods: {
    initData() {
      const teamNumber = window.location.hostname.split(".")[0];
      getLogoConfigs({ number: teamNumber }).then((data) => {
        this.loading = false;
        this.logoItem = data;

        this.$store.commit("setLogoConfig", data);

        if (this.logoItem.logo_title && this.logoItem.logo_title.length > 0) {
          document.title = `${this.logoItem.logo_title}MES - 智慧工厂管理系统`;
        } else {
          document.title = "盒木MES - 智慧工厂管理系统";
        }

        if (this.logoItem.logo_image) {
          const link = document.createElement("link");
          link.type = "image/x-icon";
          link.rel = "icon";
          link.href = `/media/${this.logoItem.logo_image_item.file.split("/").pop()}`;
          document.getElementsByTagName("head")[0].appendChild(link);
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style>
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
.panel .ant-table {
  min-height: 220px !important;
}

.warning-row {
  background-color: #fff1f0;
}
</style>
