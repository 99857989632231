import Vue from 'vue'
export default {
  state: () => ({
    config: {},
    multipage: true,
    logoItem: {},
  }),
  mutations: {
    setConfig(state, item) {
      for (let key of Object.keys(item)) {
        state.config[key] = item[key].strategy;
      }
    },
    setLogoConfig(state, item) {
      state.logoItem = item;
    },
    SET_MULTI_PAGE (state, multipageFlag) {
      Vue.ls.set('DEFAULT_MULTI_PAGE', multipageFlag)
      state.multipage = multipageFlag
    }
  },
  actions: {
    ToggleMultipage({ commit }, multipageFlag) {
      commit('SET_MULTI_PAGE', multipageFlag)
    }
  }
}