export default {
  path: "/equipment",
  name: "equipment",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "equipment_category",
      name: "equipment_category",
      meta: { title: "设备类型" },
      component: () => import("@/views/equipment/equipmentCategory"),
    },
    {
      path: "equipment",
      name: "equipment",
      meta: { title: "设备台账" },
      component: () => import("@/views/equipment/equipment"),
    },
    {
      path: "maintenance_item",
      name: "maintenance_item",
      meta: { title: "点检保养项目" },
      component: () => import("@/views/equipment/maintenanceItem"),
    },
    {
      path: "maintenance_plan",
      name: "maintenance_plan",
      meta: { title: "点检保养计划" },
      component: () => import("@/views/equipment/maintenancePlan"),
    },
    {
      path: "maintenance_task",
      name: "maintenance_task",
      meta: { title: "点检保养任务" },
      component: () => import("@/views/equipment/maintenanceTask"),
    },
    // {
    //   path: "equipment_ledger",
    //   name: "equipment_ledger",
    //   meta: { title: "设备台账" },
    //   component: () => import("@/views/equipment/equipmentLedger/index"),
    // },
    // {
    //   path: "inspection_project",
    //   name: "inspection_project",
    //   meta: { title: "点检保养项目" },
    //   component: () => import("@/views/equipment/inspectionProject/index"),
    // },
    // {
    //   path: "inspection_plan",
    //   name: "inspection_plan",
    //   meta: { title: "点检保养计划" },
    //   component: () => import("@/views/equipment/inspectionPlan/index"),
    // },
    // {
    //   path: "inspection_task",
    //   name: "inspection_task",
    //   meta: { title: "点检保养任务" },
    //   component: () => import("@/views/equipment/inspectionTask/index"),
    // },
    // {
    //   path: "repair_order",
    //   name: "repair_order",
    //   meta: { title: "维修单" },
    //   component: () => import("@/views/equipment/repairOrderList/index"),
    // },
    // {
    //   path: "repair_task",
    //   name: "repair_task",
    //   meta: { title: "维修任务" },
    //   component: () => import("@/views/equipment/repairTask/index"),
    // },
  ],
};
