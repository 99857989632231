export default {
  path: "/",
  name: "account",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "role",
      name: "role",
      meta: { title: "角色管理", permission: "role" },
      component: () => import("@/views/role/Role"),
    },
    {
      path: "account",
      name: "account",
      meta: { title: "员工账号", permission: "account" },
      component: () => import("@/views/account/Account"),
    },
    {
      path: "config",
      name: "config",
      meta: { title: "系统配置", permission: "config" },
      component: () => import("@/views/config/Config"),
    },
    {
      path: "logo_configs",
      name: "logo_configs",
      meta: { title: "Logo配置", permission: "manager" },
      component: () => import("@/views/logoConfigs/index"),
    },
    {
      path: "operation_log",
      name: "operation_log",
      meta: { title: "操作日志", permission: "operation_log" },
      component: () => import("@/views/operationLog/index"),
    },
    {
      path: "login_log",
      name: "login_log",
      meta: { title: "登录日志", permission: "login_log" },
      component: () => import("@/views/loginLog/index"),
    },
    {
      path: "update_log",
      name: "update_log",
      meta: { title: "更新日志", permission: "update_log" },
      component: () => import("@/views/updateLog/index"),
    },
    {
      path: "mobile_download",
      name: "mobile_download",
      meta: { title: "移动端下载" },
      component: () => import("@/views/mobileDownload/index"),
    },
  ],
};
